import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'FrontIndex',
    redirect: '/home',
    component: () => import('../views/front/FrontIndex'),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import('../views/front/Home')
      },
      {
        path: "post",
        name: "post",
        component: () => import('../views/front/Post')
      },
      {
        path: "type",
        name: "type",
        component: () => import('../views/front/Type')
      },
      {
        path: "tag",
        name: "tag",
        component: () => import('../views/front/Tag')
      },
      {
        path: "search",
        name: "search",
        component: () => import('../views/front/Search')
      },
      {
        path: "time",
        name: "time",
        component: () => import('../views/front/Time')
      },
      {
        path: "msg",
        name: "msg",
        component: () => import('../views/front/Msg')
      },
      {
        path: "about",
        name: "about",
        component: () => import('../views/front/About')
      }
    ]
  },
  {
    path: '/CC_Admin/cc_sign',
    name: 'sign',
    component: () => import('../views/admin/SignPage')
  },
  {
    path: '/CC_Admin/AdminIndex',
    name: 'AdminIndex',
    redirect: '/CC_Admin/AdminIndex/serverinfo',
    component: () => import('../views/admin/AdminIndex'),
    children: [
      {
        path: "serverinfo",
        name: "serverinfo",
        component: () => import('../views/admin/ServerInfo')
      },
      {
        path: "userinfo",
        name: "userinfo",
        component: () => import('../views/admin/UserInfo')
      },
      {
        path: "bloginfo",
        name: "bloginfo",
        component: () => import('../views/admin/BlogInfo')
      },
      {
        path: "postsubmit",
        name: "postsubmit",
        component: () => import('../views/admin/PostSubmit')
      },
      {
        path: "postlist",
        name: "postlist",
        component: () => import('../views/admin/PostList')
      },
      {
        path: "posttop",
        name: "posttop",
        component: () => import('../views/admin/PostTop')
      },
      {
        path: "posttype",
        name: "posttype",
        component: () => import('../views/admin/PostType')
      },
      {
        path: "posttag",
        name: "posttag",
        component: () => import('../views/admin/PostTag')
      },
      {
        path: "timeline",
        name: "timeline",
        component: () => import('../views/admin/TimeLine')
      },
      {
        path: "message",
        name: "message",
        component: () => import('../views/admin/Message')
      },
      {
        path: "aboutme",
        name: "aboutme",
        component: () => import('../views/admin/AboutMe')
      }
    ]
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
