// import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import request from '@/utils/request'
import ElementPlus from 'element-plus'
import mavonEditor from 'mavon-editor'
// import 'element-plus/dist/index.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'mavon-editor/dist/css/index.css'

//后端地址
const serverUrl = "https://www.codercc.net"
localStorage.setItem("serverUrl", serverUrl)

// 根据vue.config.js->externals配置，变更使用方式
const app = Vue.createApp(App)
app.config.globalProperties.$request = request
app.provide("serverUrl", serverUrl)
app.use(router)
app.use(ElementPlus)
app.use(mavonEditor)
app.mount('#app')